<template>
  <div
    class="d-flex flex-row align-items-center justify-center login ems-light"
  >
    <v-card class="ma-auto pa-5" width="360" rounded="xl" flat>
      <v-card-title class="headline font-weight-bold">
        {{ settings.title }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="login"
          lazy-validation
          autocomplete="off"
          @keyup.native.enter="validate"
        >
          <div>請輸入登入資料</div>
          <v-text-field
            label="帳號"
            v-model.trim="loginForm.username"
            :rules="rule.username"
            prepend-icon="mdi-account"
            required
          ></v-text-field>
          <v-text-field
            type="password"
            label="密碼"
            v-model.trim="loginForm.password"
            :rules="rule.password"
            prepend-icon="mdi-lock"
            required
          ></v-text-field>
          <v-alert color="error lighten-1" dark class="mt-3" v-if="error">
            {{ error }}
          </v-alert>
          <v-btn
            class="mt-4"
            color="primary"
            large
            block
            @click="validate"
            :loading="loadingLogin"
            :disabled="
              loadingLogin || !loginForm.username || !loginForm.password
            "
          >
            登入
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import settings from '@/settings'
import Edu from '@/api/Edu'

export default {
  name: 'Login',
  data: () => ({
    settings: settings,
    loginForm: {
      username: null,
      password: null
    },
    rule: {
      username: [(v) => !!v || 'Username is required'],
      password: [(v) => !!v || 'Password is required']
    },
    error: '',
    loadingLogin: false
  }),
  watch: {
    'loginForm.password'() {
      this.error = null
    },
    'loginForm.username'() {
      this.error = null
    }
  },
  mounted: function () {},
  methods: {
    ...mapActions({
      login: 'user/login'
    }),
    validate() {
      const validate = this.$refs.login.validate()
      if (validate) {
        this.submit()
      }
    },
    async submit() {
      this.error = ''
      try {
        this.loadingLogin = true
        await this.login(this.loginForm)
        this.$router.push({ path: '/' })
      } catch (error) {
        console.log(error)
        let msg = ''
        if (error && error.data) {
          msg = error.data.error_description
        }
        this.error = msg || '帳號或密碼錯誤，請重新輸入'
      } finally {
        this.loadingLogin = false
      }
    },
    loginEdu() {
      Edu.auth()
    }
  }
}
</script>

<style scoped>
/* .icon {
  width: 1rem;
} */
.login {
  height: 100vh;
  overflow: hidden;
}
</style>
