import settings from '@/settings'
import moment from 'moment'

/**
 * edu 相關 API
 */
class _Edu {
  /**
   * OAuth 綁定使用者(使用者必須為登入狀態)
   */
  auth() {
    const config = {
      clientid: settings.edu.client_id,
      // clientsecret: settings.edu.client_secret,
      redirecturi: `${window.origin}${settings.publicPath}/openid/callback`,
      allowingscopes: ['openid', 'profile', 'email', 'eduinfo']
    }
    // window.location.href = `https://chc.sso.edu.tw/auth-server-stlogin?Auth_Request_RedirectUri=${encodeURIComponent(
    //   encodeURIComponent(config.redirecturi)
    // )}&Auth_Request_State=none&Auth_Request_Response_Type=code&Auth_Request_Client_ID=${
    //   config.clientid
    // }&Auth_Request_Nonce=_pFWU8VbN43yfGlOGgutRZODR6iCp_10LN8aa4IMy-s&Auth_Request_Scope=${config.allowingscopes.join(
    //   '+'
    // )}&local=true`
    window.location = `${
      settings.edu.path
    }/oidc/v1/azp?response_type=code&client_id=${
      config.clientid
    }&redirect_uri=${encodeURIComponent(
      config.redirecturi
    )}&scope=${config.allowingscopes.join(
      '+'
    )}&state=none&nonce=_pFWU8VbN43yfGlOGgutRZODR6iCp_10LN8aa4IMy-s
    `
  }

  authTainan() {
    const currentTime = new Date()
    let stateList = JSON.parse(localStorage.getItem('stateList')) || []
    stateList = stateList.filter(
      (s) => s && moment(new Date(atob(s))).add(10, 'm') > currentTime
    )
    const state = btoa(new Date().toISOString())
    stateList.push(state)
    localStorage.setItem('stateList', JSON.stringify(stateList))
    window.location = `/api/openid/tainan/login?state=${state}`
  }
}

var Edu = new _Edu()
export default Edu
